import {useAuth} from "@/composables/auth";
import axios from "axios";
axios.defaults.headers.lang = localStorage.getItem("locale") || "ar";

const api = axios.create({
	baseURL: "https://backend.coffeekies.com/api/",
});

api.interceptors.request.use(
	(config) => {
		const {accessToken} = useAuth();
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
api.interceptors.response.use(
	(response) => response,
	(error) => {
		if (axios.isAxiosError(error)) {
			if (error.response) {
				if (error.response.status === 403 || error.response.status === 401) {
					const {set} = useAuth();
					set("token", "");
				}
				const errorResponse = error.response.data;
				return Promise.reject(errorResponse);
			} else if (error.request) {
				// The request was made, but no response was received
				return Promise.reject(new Error("No response received from the server."));
			} else {
				// Something happened in setting up the request that triggered an Error
				return Promise.reject(new Error(error.message));
			}
		}
	}
);
export default api;
