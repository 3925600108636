<template>
	<div class="container mt-5">
		<!-- breadcrumb  -->
		<div class="breadcrumb d-flex">
			<router-link to="/" class="inActive"> {{ $t("profile.main") }}</router-link
			>&nbsp; - &nbsp;
			<p class="active mainColor">{{ $t("notes") }}</p>
		</div>

		<div class="row">
			<h5 class="fw-bold red mb-3">{{ $t("note-desc") }}</h5>

			<section v-if="notifications.length > 0">
				<!-- single not  -->
				<div class="col-md-10 col-12" v-for="not in notifications" :key="not.id">
					<div class="mt-3 mb-3">
						<div v-if="not.type == 'store_accept_order'" class="notificationBox position-relative">
							<div class="d-flex align-items-center gap-10">
								<!-- <img :src="require('@/assets/imgs/SSM 1.png')" alt="" class="notificationLogo"> -->
								<!-- body  -->
								<div>
									<h6 class="fw-6" style="color: #15364d">{{ not.title }}</h6>
									<div class="grow-1 fw-6" style="color: #15364d; font-size: 16px">
										{{ not.body }}
									</div>
								</div>

								<div class="mx-4">
									<span @click="goToPayment(not.order_id)" class="btn main_btn"> {{ $t("payNow") }} </span>
								</div>
							</div>
							<div class="text-start">
								<span class="date">
									<i class="fa-solid fa-clock m-end-5"></i>
								</span>
								<span class="date">
									{{ not.created_at }}
								</span>
							</div>

							<!-- delete not  -->
							<button class="btn btn-danger text-white delete_not" @click="deleteNotification(not.id)">
								<i class="fa-solid fa-trash-can"></i>
							</button>
						</div>
						<div v-if="not.type == 'store_finish_order'" class="notificationBox position-relative">
							<div class="d-flex align-items-center gap-10">
								<!-- <img :src="require('@/assets/imgs/SSM 1.png')" alt="" class="notificationLogo"> -->
								<!-- body  -->
								<div>
									<h6 class="fw-6" style="color: #15364d">{{ not.title }}</h6>
									<div class="grow-1 fw-6" style="color: #15364d; font-size: 16px">
										{{ not.body }}
									</div>
								</div>

								<div class="mx-4">
									<router-link to="/complete" class="btn main_btn">{{ $t("rateStore") }} </router-link>
								</div>
							</div>
							<div class="text-start">
								<span class="date">
									<i class="fa-solid fa-clock m-end-5"></i>
								</span>
								<span class="date">
									{{ not.created_at }}
								</span>
							</div>

							<!-- delete not  -->
							<button class="btn btn-danger text-white delete_not" @click="deleteNotification(not.id)">
								<i class="fa-solid fa-trash-can"></i>
							</button>
						</div>
						<div
							v-if="not.type != 'store_finish_order' && not.type != 'store_accept_order'"
							class="notificationBox position-relative"
						>
							<div class="d-flex align-items-center gap-10">
								<!-- <img :src="require('@/assets/imgs/SSM 1.png')" alt="" class="notificationLogo"> -->
								<!-- body  -->
								<div>
									<h6 class="fw-6" style="color: #15364d">{{ not.title }}</h6>
									<div class="grow-1 fw-6" style="color: #15364d; font-size: 16px">
										{{ not.body }}
									</div>
								</div>
							</div>
							<div class="text-start">
								<span class="date">
									<i class="fa-solid fa-clock m-end-5"></i>
								</span>
								<span class="date">
									{{ not.created_at }}
								</span>
							</div>

							<!-- delete not  -->
							<button class="btn btn-danger text-white delete_not" @click="deleteNotification(not.id)">
								<i class="fa-solid fa-trash-can"></i>
							</button>
						</div>
					</div>
				</div>
			</section>

			<Paginator :rows="perPage" :totalRecords="total" :page="currentPage" @page="pageClickHandler" class="paginator" />
		</div>
	</div>
	<Toast />
</template>
<script>
import api from "@/core/api";
import Toast from "primevue/toast";
import Paginator from "primevue/paginator";

export default {
	data() {
		return {
			notifications: [],
			currentPage: 0,
			perPage: 10,
			total: 0,
			disabled: false,
		};
	},

	components: {
		Paginator,
		Toast,
	},
	methods: {
		async getNotification() {
			try {
				const token = localStorage.getItem("token");
				const headers = {
					Authorization: `Bearer ${token}`,
				};
				console.log(this.currentPage);
				await api
					.get("user/notifications", {
						headers,
						params: {
							page: this.currentPage + 1,
						},
					})
					.then((res) => {
						if (res.data.key === "success") {
							this.notifications = res.data.data.notifications;
							this.currentPage = res.data.data.pagination.current_page - 1;
							this.total = res.data.data.pagination.total;
							this.per_page = res.data.data.pagination.per_page;
						}
					});
			} catch (err) {
				this.$toast.add({severity: "error", summary: err, life: 3000});
			}
		},
		goToPayment(order_id) {
			this.$router.push("/orderDetails/" + order_id);
		}, // delete notification
		async deleteNotification(id) {
			const token = localStorage.getItem("token");
			const headers = {
				Authorization: `Bearer ${token}`,
			};
			// const fd = new FormData();
			await api
				.delete(`user/delete-notification/${id}`, {headers})
				.then((res) => {
					if (res.data.key === "success") {
						this.$toast.add({severity: "success", summary: res.data.msg, life: 3000});
						setTimeout(() => {
							this.getNotification();
						}, 1000);
					} else {
						this.$toast.add({severity: "error", summary: res.data.msg, life: 3000});
					}
				})
				.catch((err) => {
					this.$toast.add({severity: "error", summary: err, life: 3000});
					this.disabled = false;
				});
		},
		pageClickHandler(event) {
			this.currentPage = event.page;
			this.getNotification();
		},
	},
	mounted() {
		this.getNotification();
	},
};
</script>

<style lang="scss">
.delete_not {
	position: absolute;
	left: 20px;
	top: 10px;
}
.page-link {
	font-size: 11px !important;
	width: 30px !important;
	height: 30px !important;
	display: flex !important;
	justify-content: center !important;
	border-radius: 50% !important;
	margin: 0 5px;
	color: #333 !important;
	&.active {
		background: #2a3255 !important;
		border: 1px solid #2a3255 !important;
		color: #fff !important;
	}
}
</style>
