<template>
	<div class="home">
		<offerComponentVue />
	</div>
</template>

<script>
import offerComponentVue from "@/components/client/pointsComponent.vue";
import api from "@/core/api";

export default {
	name: "HomeView",
	components: {
		offerComponentVue,
	},
	methods: {
		async getProfile() {
			await api.get("user/show-profile", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
		},
	},
	mounted() {
		this.getProfile();
	},
};
</script>
