<template>
	<div class="home">
		<becomePartener />
	</div>
</template>

<script>
import becomePartener from "@/components/vendor/becomePartener.vue";
export default {
	components: {
		becomePartener,
	},
};
</script>
