<template>
	<section id="orders" class="mt-4">
		<div class="container">
			<!-- breadcrumb -->
			<div class="breadcrumb d-flex">
				<router-link to="/" class="inActive"> {{ $t("profile.main") }} </router-link>&nbsp; - &nbsp;
				<p class="active mainColor">{{ $t("order.mine") }}</p>
			</div>

			<!-- content -->
			<section>
				<h5 class="fw-bold red mb-3">{{ $t("order.mine") }}</h5>

				<div class="statuses d-flex justify-content-center align-items-center">
					<div class="w-25 mx-4 position-relative">
						<span class="badge-count">{{ count.pending }}</span>
						<button
							class="main_btn w-100"
							:class="{'active-btn': type === 'pending'}"
							@click="reservationType('pending')"
						>
							{{ $t("order.pending") }}
						</button>
					</div>
					<div class="w-25 mx-4 position-relative">
						<span class="badge-count">{{ count.waiting_payment }}</span>
						<button
							class="main_btn w-100"
							:class="{'active-btn': type === 'waiting_payment'}"
							@click="reservationType('waiting_payment')"
						>
							{{ $t("order.waitPayment") }}
						</button>
					</div>
					<div class="w-25 mx-4 position-relative">
						<span class="badge-count">{{ count.inprogress }}</span>
						<button
							class="main_btn w-100"
							:class="{'active-btn': type === 'inprogress'}"
							@click="reservationType('inprogress')"
						>
							{{ $t("order.inProgress") }}
						</button>
					</div>
					<div class="w-25 mx-4 position-relative">
						<span class="badge-count">{{ count.finished }}</span>
						<button
							class="main_btn w-100"
							:class="{'active-btn': type === 'finished'}"
							@click="reservationType('finished')"
						>
							{{ $t("order.finished") }}
						</button>
					</div>
				</div>

				<div class="row mt-4" v-if="orders.length > 0">
					<div class="col-md-6 mb-3" v-for="order in orders" :key="order.id">
						<router-link :to="'orderDetails/' + order.id">
							<section class="single_card">
								<div class="d-flex justify-content-between align-items-baseline">
									<section>
										<h6>{{ $t("order.storeInfo") }}</h6>
										<div class="card_image">
											<img :src="order.store.image" alt="" />
										</div>
										<div class="card_details mx-3">
											<p class="fw-bold">{{ order.store.name }}</p>
										</div>
									</section>

									<section>
										<h6>{{ $t("order.clientInfo") }}</h6>
										<div class="card_details mx-3">
											<p class="fw-bold">{{ order.user.name }}</p>
										</div>
										<div class="card_details mx-3">
											<p class="fw-bold">{{ order.user.phone }}</p>
										</div>
									</section>
								</div>
								<div class="abs_details fw-bold">
									<span class="grayColor mx-2"> {{ $t("order.orderDate") }}: </span>
									<span> {{ order.order_date }} </span>
								</div>
							</section>
						</router-link>
					</div>
				</div>

				<!-- Paginator -->
				<Paginator
					v-if="orders.length > 0"
					:rows="perPage"
					:totalRecords="totalRecords"
					:page="currentPage"
					@page="pageClickHandler"
					class="paginator mt-4"
				/>
			</section>
		</div>
	</section>
</template>

<script>
import Paginator from "primevue/paginator";
import api from "@/core/api";
export default {
	components: {
		Paginator,
	},
	data() {
		return {
			orders: [],
			count: {pending: 0, waiting_payment: 0, inprogress: 0, finished: 0},
			load: true,
			LenghtZero: false,
			type: "pending",
			currentPage: 1,
			perPage: 10,
			totalRecords: 0,
		};
	},

	methods: {
		reservationType(type) {
			this.type = type;
			this.currentPage = 1; // Reset to first page when switching types
			this.getOrders();
		},
		async getOrders() {
			const token = localStorage.getItem("token");
			const headers = {Authorization: `Bearer ${token}`};
			await api
				.get(`user/orders`, {
					headers,
					params: {
						status: this.type,
						page: this.currentPage,
					},
				})
				.then((res) => {
					if (res.data.key === "success") {
						this.orders = res.data.data.orders;
						this.totalRecords = this.orders.length; // Update total records
						this.load = false;
					} else {
						this.LenghtZero = true;
						this.load = false;
					}
				});
		},
		async getOrdersCount() {
			try {
				const token = localStorage.getItem("token");
				const headers = {Authorization: `Bearer ${token}`};
				await api.get("user/count-orders", {headers}).then((res) => {
					const result = res.data.data;
					this.count["pending"] = result.pending;
					this.count["waiting_payment"] = result["waiting_payment "];
					this.count["inprogress"] = result["inprogress "];
					this.count["finished"] = result.finished;
				});
			} catch (error) {
				console.error("Error fetching order counts:", error);
			}
		},
		pageClickHandler(event) {
			this.currentPage = event.page + 1; // PrimeVue uses zero-based index for page
			this.getOrders();
		},
	},
	mounted() {
		this.getOrders();
		this.getOrdersCount();
	},
};
</script>

<style lang="scss" scoped>
a {
	color: inherit !important;
	text-decoration: none !important;
}
.single_card {
	box-shadow: 0px 0px 10px #33333325;
	border: 1px solid #ccc;
	border-radius: 10px;
	padding: 10px;
	.card_image {
		img {
			width: 50px;
			height: 50px;
			object-fit: cover;
			border-radius: 50%;
		}
	}
}
.statuses {
	position: relative;
	.badge-count {
		position: absolute;
		top: -10px;
		left: -10px;
		background-color: red;
		color: white;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		display: flex;
		z-index: 2;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: bold;
	}
}
.statuses button.active-btn {
	filter: brightness(70%); /* Darken the button color slightly */
}
.orderStatus {
	width: 100px;
	height: 30px;
	border-radius: 4px;
	padding: 5px;
	&.applied {
		background-color: #f1dcc9;
	}
	&.accepted {
		background-color: #d5f2cb;
		span {
			color: #316d27;
		}
	}
	&.refused {
		background-color: #f1c9c9;
		span {
			color: #f44336;
		}
	}
	&.finished {
		background-color: #dcecff;
		span {
			color: #253974;
		}
	}
}
</style>
