<template>
	<div class="home">
		<LocationComponentVue />
		<categoriesComponentVue :categories="categories" :loadingCategories="loading" />
		<offersComponentVue :ads="ads" :loadingOffers="loading" />
		<branchesComponentVue :cities="cities" :loadingCities="loading" />
		<downloadAppVue />
	</div>
</template>

<script>
import LocationComponentVue from "@/components/client/LocationComponent.vue";
import categoriesComponentVue from "@/components/client/categoriesComponent.vue";
import offersComponentVue from "@/components/client/offersComponent.vue";
import branchesComponentVue from "@/components/client/branchesComponent.vue";
import downloadAppVue from "@/components/client/downloadApp.vue";
import api from "@/core/api";

export default {
	name: "HomeView",
	data() {
		return {
			cities: [],
			ads: [],
			loading: true,
			categories: [],
			country_id: "",
			best_stores: [],
		};
	},
	components: {
		LocationComponentVue,
		categoriesComponentVue,
		offersComponentVue,
		branchesComponentVue,
		downloadAppVue,
	},
	methods: {
		async getHome() {
			await api
				.get(
					`user/home?lat=${localStorage.getItem("lat") || 0}&long=${localStorage.getItem("lng") || 0}&country_id=${
						this.currentCountry.id
					}`
				)
				.then((res) => {
					this.categories = res.data.data.categories;
					this.ads = res.data.data.ads;
					this.cities = res.data.data.cities;
					this.best_stores = res.data.data.best_stores;
					this.loading = false;
				});
		},
		async getCountries() {
			await api.get("countries").then((res) => {
				this.$store.commit("setCountries", res.data.data);
				this.$store.commit("setCountry", res.data.data[0]);
			});
		},
	},
	watch: {
		countryId(newId, oldId) {
			// This function will run whenever the country id changes
			this.getHome();
			console.log(newId, oldId);
		},
	},
	computed: {
		currentCountry() {
			return this.$store.state.country;
		},
		countryId() {
			return this.$store.state.country.id;
		},
	},
	mounted() {
		this.getCountries().then(() => {
			this.getHome();
		});
	},
};
</script>
