<template>
	<section id="orders" class="mt-4">
		<div class="container">
			<!-- breadcrumb  -->
			<div class="breadcrumb d-flex">
				<router-link to="/" class="inActive"> {{ $t("profile.main") }} </router-link>&nbsp; - &nbsp;
				<p class="active mainColor">{{ $t("order.reserves") }}</p>
			</div>

			<!-- content  -->
			<section>
				<h5 class="fw-bold red mb-3">{{ $t("order.reserves") }}</h5>

				<!-- <div v-if="load" class="row">
                    <div class="col-md-6 mb-3" v-for="skeleton in 2" :key="skeleton">
                        <Skeleton style="width:100%" height="4rem"></Skeleton>
                    </div>
                </div> -->

				<div class="statuses d-flex justify-content-center align-items-center">
					<div class="w-25 mx-4 position-relative">
						<span class="badge-count">{{ count.pending }}</span>
						<button :class="{'active-btn': type === 0}" class="main_btn w-100" @click="reservationType(0)">
							{{ $t("order.pending") }}
						</button>
					</div>
					<div class="w-25 mx-4 position-relative">
						<span class="badge-count">{{ count.accepted }}</span>
						<button :class="{'active-btn': type === 1}" class="main_btn w-100" @click="reservationType(1)">
							{{ $t("order.accepted") }}
						</button>
					</div>
					<div class="w-25 mx-4 position-relative">
						<span class="badge-count">{{ count.refused }}</span>
						<button :class="{'active-btn': type === 2}" class="main_btn w-100" @click="reservationType(2)">
							{{ $t("order.refused") }}
						</button>
					</div>
				</div>
				<div class="row mt-4" v-if="orders.length > 0">
					<div class="col-md-6 mb-3" v-for="order in orders" :key="order.id">
						<router-link :to="'reservationDetails/' + order.id">
							<section class="single_card">
								<div class="d-flex justify-content-between align-items-baseline">
									<!-- card image  -->
									<section>
										<h6>
											{{ $t("order.storeInfo") }}
										</h6>
										<div class="card_image">
											<img :src="order.store.image" alt="" />
										</div>

										<!-- card details  -->
										<div class="card_details mx-3">
											<p class="fw-bold">{{ order.store.name }}</p>
										</div>
									</section>

									<section>
										<h6>
											{{ $t("order.clientInfo") }}
										</h6>

										<!-- card details  -->
										<div class="card_details mx-3">
											<p class="fw-bold">{{ order.user.name }}</p>
										</div>
										<div class="card_details mx-3">
											<p class="fw-bold">{{ order.user.phone }}</p>
										</div>
									</section>
								</div>
								<!-- time  -->
								<div class="abs_detailsfw-bold fw-bold">
									<!-- <img :src="require('@/assets/imgs/clock.svg')" alt=""> -->
									<span class="grayColor mx-2"> {{ $t("order.reserveDate") }}: </span>
									<span> {{ order.reservation_date }} </span>
								</div>
							</section>
						</router-link>
					</div>
				</div>

				<div v-else class="text-center text-danger">
					<Message severity="error"> {{ $t("order.reserveEmoty") }} </Message>
				</div>
			</section>
			<Paginator
				v-if="orders.length > 0"
				:totalRecords="total"
				:rows="perPage"
				:page="currentPage"
				@page="handlePageChange"
				class="paginator"
			/>
		</div>
	</section>
</template>

<script>
import api from "@/core/api";
import Message from "primevue/message";
import Paginator from "primevue/paginator";

// import Skeleton from 'primevue/skeleton';

// import paginationComponentVue from '../layout/paginationComponent.vue'
export default {
	data() {
		return {
			orders: [],
			count: {pending: 0, waiting_payment: 0, inprogress: 0, finished: 0},
			load: true,
			LenghtZero: false,
			type: 0,
			currentPage: 1,
			perPage: 10,
			total: 0,
		};
	},
	components: {
		// paginationComponentVue ,
		Paginator,
		Message,
	},
	methods: {
		reservationType(type) {
			this.type = type;
			this.getOrders();
		},
		handlePageChange(event) {
			this.currentPage = event.page + 1;
			this.getOrders();
		},
		// get orders
		async getOrders() {
			const token = localStorage.getItem("token");
			const headers = {
				Authorization: `Bearer ${token}`,
			};
			await api
				.get(`user/reservations?status=${this.type}`, {
					headers,
					params: {
						page: this.currentPage,
					},
				})
				.then((res) => {
					if (res.data.key == "success") {
						this.orders = res.data.data.reservations;
						this.total = res.data.data.pagination.total; // Update total
					}
				});
		},
		async getReservationsCount() {
			try {
				const token = localStorage.getItem("token");
				const headers = {
					Authorization: `Bearer ${token}`,
				};
				await api.get(`user/count-reservations`, {headers}).then((res) => {
					let result = res.data.data;
					this.count["pending"] = result.pending;
					this.count["accepted"] = result["accepted "];
					this.count["refused"] = result["refused "];
				});
			} catch (error) {
				console.error("Error fetching cart:", error);
			}
		},
	},

	mounted() {
		this.getOrders();
		this.getReservationsCount();
	},
};
</script>

<style lang="scss" scoped>
a {
	color: inherit !important;
	text-decoration: none !important;
}
.statuses button.active-btn {
	filter: brightness(70%); /* Darken the button color slightly */
}
.statuses {
	position: relative;
	.badge-count {
		position: absolute;
		top: -10px;
		left: -10px;
		background-color: red;
		color: white;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		display: flex;
		z-index: 2;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: bold;
	}
}
.single_card {
	box-shadow: 0px 0px 10px #33333325;
	border: 1px solid #ccc;
	border-radius: 10px;
	padding: 10px;
	.card_image {
		img {
			width: 50px;
			height: 50px;
			object-fit: cover;
			border-radius: 50%;
		}
	}
}
.orderStatus {
	width: 100px;
	height: 30px;
	border-radius: 4px;
	padding: 5px;
	&.applied {
		background-color: #f1dcc9;
	}
	&.accepted {
		background-color: #d5f2cb;
		span {
			color: #316d27;
		}
	}
	&.refused {
		background-color: #f1c9c9;
		span {
			color: #f44336;
		}
	}
	&.finished {
		background-color: #dcecff;
		span {
			color: #253974;
		}
	}
}
</style>
