export default {
  "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept terms and conditions"])},
  "nav": {
    "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Account"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
    "rerserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Reservations"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Points"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Orders"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers&Discounts"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "join_partener": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partener"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Us"])}
  },
  "notFound": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! The page you're looking for doesn't exist."])},
    "goHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back Home"])}
  },
  "dialog": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login is needed  "])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to login to continue !"])},
    "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "home": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a city, address, or anything you want"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your location"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order your coffee online anytime"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Our Products"])},
    "discoverDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An amazing world of flavors, aromas, and delightful tastes"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "offersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazing discounts, an opportunity to save and enjoy shopping"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Our Offers and Discounts"])},
    "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show More"])},
    "whereBranches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are our stores located?"])},
    "branches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are our branches"])},
    "appDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get everything you need, whenever you need it"])},
    "App": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover our new app"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "whoUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who We Are"])},
    "aboutApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Our App"])},
    "famous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Most Famous Stores"])},
    "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores"])},
    "not_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cities near you"])}
  },
  "store": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No stores available"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed now"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the nearest city to you"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the type of store"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a store name"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Our Stores"])}
  },
  "single": {
    "rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
    "opinions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Reviews about the Store"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Now"])},
    "mostSell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Selling"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "real": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riyal"])},
    "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% on every purchase over"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a Discount"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Store Menu"])}
  },
  "prod": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products available"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Cart"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Products"])},
    "mostRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Rated"])},
    "depend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose by"])},
    "cat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose by Category"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a product name"])},
    "goCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Cart"])}
  },
  "cart": {
    "simDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users also usually order these items"])},
    "similar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar Products"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Pickup Time"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Pickup Date"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
    "chooseAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Add-ons"])},
    "chooseSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Sizes"])},
    "prodTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Total"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Cart"])}
  },
  "order": {
    "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate the Store"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with your points"])},
    "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay online"])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Orders"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Paid"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Added Tax"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "receiveTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup Time"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Creation Date"])},
    "receiveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Pickup Date"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Status"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify the number of seats"])},
    "reTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Timing"])},
    "resDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Date"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Reservation"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers"])},
    "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts"])},
    "noDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No discounts available yet"])},
    "noOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No offers available yet"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get instant discounts on selected items"])},
    "mine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
    "waitPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for Payment"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Approval"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No orders available yet"])},
    "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Date"])},
    "clientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Information"])},
    "storeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Information"])},
    "reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Reservations"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused"])},
    "reserveEmoty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reservations available yet"])},
    "reserveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Date"])},
    "membersNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Seats"])},
    "reserveTimeDet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Time"])},
    "reserveDetailDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "reserveDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Details"])}
  },
  "profile": {
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "addressDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a city, address, or anything you want"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])},
    "phoneDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your mobile number"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "lastDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your last name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "firstDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your first name"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can edit your personal details here"])},
    "changeEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can change your password here"])}
  },
  "auth": {
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "dontIden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does not match"])},
    "iden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matches"])},
    "confirmDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password confirmation"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "newPassDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "oldPassDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter old password"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])}
  },
  "be": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow Your Online Business with CoffeeKies!"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us to reach more customers, increase revenue, and expand your business online – your success story starts here."])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Now"])},
    "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why should you"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partner with us?"])},
    "reach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach more customers"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a large number of customers in your area who want to order from you, and we will help you deliver coffee to them as quickly as possible."])},
    "money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earn more money"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will help you serve more hungry customers without increasing the number of seats in your restaurant, and we guarantee you instant payment."])},
    "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow your business"])},
    "tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase sales, reach more customers, or market your business better. We offer solutions to improve your business because our success is tied to your success."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will we work together?"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer places a coffee order"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer sends the order through the CoffeeKies app."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You prepare the order"])},
    "make": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive a notification to start preparing the order."])},
    "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch your business grow"])},
    "catch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track your sales, manage orders, invest in marketing, and more using your personal store portal."])},
    "any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any questions?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We answer everything for you."])}
  },
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "CopyRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved"])},
  "con-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep Contact Us"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "note-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Can Show Notifications Here"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "rateStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate Store"])},
  "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])},
  "cartEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart Empty"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
}