<template>
	<section class="auth">
		<section class="login_form flex_center flex-column">
			<!-- login image  -->
			<div class="login_image mb-4" style="width: 150px; height: 150px">
				<img src="@/assets/imgs/logo.png" alt="" />
			</div>

			<h5 class="fw-bold mb-3 mainColor">مرحبا بك</h5>

			<div class="reg_issue">
				<span> قم بإدخال رقم الهاتف </span>
			</div>

			<form ref="loginForm" @submit.prevent="login" class="w-100 main_form">
				<div class="form-group mb-3 w-100 flex-column d-flex align-items-start position-relative">
					<label for=""> رقم الجوال </label>
					<div class="w-100 gap-1 d-flex">
						<InputText
							v-model="phone"
							class="w-75 bg-white border"
							inputId="withoutgrouping"
							:useGrouping="false"
							placeholder="يرجى ادخال رقم الجوال"
						/>
						<Dropdown v-model="selectedCountry" :options="countries" optionLabel="code" class="w-25 bg-white px-1" />
					</div>
				</div>

				<!-- <div class="flex_end mt-3 mb-4">
          <router-link to="/" class="forget_password fs-16">
            نسيت كملة المرور ؟
          </router-link>
        </div> -->

				<div class="flex_center mb-4 mt-4 w-100">
					<button class="pt-3 br-5 pb-3 px-5 main_btn btn w-100" :disabled="disabled">
						<span v-if="!disabled">استمرار</span>
						<ProgressSpinner v-else />
					</button>
				</div>
			</form>
		</section>

		<!-- logo image  -->
		<div class="logo_image">
			<!-- <img :src="require('@/assets/imgs/logo.png')" alt="logo"> -->
		</div>
		<!-- circled  -->
		<span class="top_left_circle circle"></span>
		<span class="bottom_right_circle circle"></span>
	</section>
	<Toast />

	<!-- otp  -->
	<Dialog v-model:visible="otp" modal :style="{width: '40rem'}" :breakpoints="{'1199px': '75vw', '575px': '90vw'}">
		<activeCode @closeOtpModal="otp = false" @responsibleData="saveResponsible" />
	</Dialog>
</template>

<script>
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import ProgressSpinner from "primevue/progressspinner";
import api from "@/core/api";

import Toast from "primevue/toast";

import activeCode from "@/components/client/forgetActiveCode.vue";

import Dialog from "primevue/dialog";

export default {
	data() {
		return {
			countries: [],
			disabled: false,
			password: "",
			phone_email: "",
			phone: "",
			selectedCountry: null,
			otp: false,
		};
	},

	mounted() {
		this.getCountries();

		fetch("https://api.ipify.org?format=json")
			.then((response) => response.json())
			.then((data) => localStorage.setItem("device_id", data.ip))
			.catch((error) => console.error(error));
	},

	methods: {
		// get countries
		async getCountries() {
			await api.get("countries").then((res) => {
				this.countries = res.data.data;
			});
		},

		// login
		async login() {
			this.disabled = true;
			const fd = new FormData();
			fd.append("phone", this.phone);
			fd.append("country_key", this.selectedCountry.code);

			await api
				.post("user/forget-password", fd, {
					headers: {
						lang: "ar",
					},
				})
				.then((res) => {
					if (res.data.key == "success") {
						this.$toast.add({severity: "success", summary: res.data.msg, life: 4000});
						sessionStorage.setItem("phone", this.phone);
						sessionStorage.setItem("country_key", this.selectedCountry.code);
						setTimeout(() => {
							this.otp = true;
						}, 2000);
					} else {
						this.$toast.add({severity: "error", summary: res.data.msg, life: 4000});
					}
					this.disabled = false;
				});
		},
	},
	components: {
		InputText,
		Dropdown,
		ProgressSpinner,
		Toast,
		Dialog,
		activeCode,
	},
};
</script>

<style lang="scss" scoped>
:deep(.country_code .p-dropdown) {
	position: absolute;
	left: -199px !important;
	top: 34px !important;
	width: 85px;
	background: #f6f6f6 !important;
}
:deep(.country_code .p-dropdown .p-dropdown-trigger) {
	background: #f6f6f6 !important;
	color: #6b7280;
	width: 1rem;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
label {
	color: #000;
	font-size: 16px;
	display: block;
	margin-bottom: 10px;
	font-weight: 600;
}
.login_image {
	width: 120px;
	height: 120px;
	border-radius: 10px;
	img {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}
}
.main_form {
	padding: 1rem 1rem;
}

@media screen and (max-width: 768px) {
	.main_form {
		padding: 1rem 2rem;
	}
}

.country_code .p-dropdown {
	position: absolute;
	left: 0;
	top: -71px;
	width: 85px;
}
.country_code .p-dropdown .p-dropdown-trigger {
	background: #f6f6f6;
	color: #6b7280;
	width: 1rem;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.br-5 {
	border-radius: 5px !important;
}
.auth {
	position: relative;
	background-image: url("@/assets/imgs/coffee1.webp");
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	overflow: hidden;
	.login_form {
		background-color: #fff;
		border-top-left-radius: 35px;
		border-bottom-left-radius: 35px;
		width: 45%;
		min-height: 100vh;

		.login_image {
			width: 250px;
			height: 200px;
		}

		h5 {
			color: #000;
		}

		.login_form {
			padding: 1rem 1rem;
		}

		@media screen and (max-width: 768px) {
			.login_form {
				padding: 1rem 2rem;
			}
		}

		.reg_issue {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 15px;
			.login_underLine {
				color: #346cf6 !important;
				text-decoration: underline !important;
			}
		}
	}
	.logo_image {
		position: absolute;
		top: 36%;
		left: 30%;
	}
	.circle {
		width: 400px;
		height: 400px;
		border-radius: 50%;
		position: absolute;
		background-color: #d8c326aa;
		&.top_left_circle {
			top: -20%;
			left: -10%;
		}
		&.bottom_right_circle {
			bottom: -30%;
			right: 39%;
		}
	}
}

.p-inputtext {
	color: #4b5563;
	background: #f6f6f6;
	border: none;
	width: 400px;
	height: 55px;
	text-align: start;
}
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > i:last-of-type {
	right: auto;
	left: 0.75rem;
}
.p-input-icon-right > .p-inputtext {
	padding-right: 0.75rem;
	padding-left: 2.5rem;
}
</style>
