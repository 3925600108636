<template>
	<div>
		<div class="header">
			<div class="container px-m-0 mobile-header">
				<div class="nav-bar navbar-expand-sm d-flex justify-content-between align-items-baseline">
					<router-link @click="toggleNav" to="/" class="decoration-none">
						<div class="logo">
							<img :src="require('@/assets/imgs/logo.png')" alt="" />
						</div>
					</router-link>

					<ul class="navbar-nav d-none d-md-flex gap-4 px-4">
						<li class="nav-item pa-m-0 offers-li px-sm">
							<router-link @click="toggleNav" class="d-block text-sm pa-m-10 f-m-14" to="/markets">
								{{ $t("nav.stores") }}
							</router-link>
						</li>
						<li class="nav-item pa-m-0 offers-li px-sm">
							<router-link @click="toggleNav" class="d-block text-sm pa-m-10 f-m-14" to="reserve">
								{{ $t("nav.reserve") }}
							</router-link>
						</li>
						<li class="nav-item pa-m-0 become-partner-li px-sm">
							<router-link @click="toggleNav" rel="noreferrer" class="d-block text-sm pa-m-10 f-m-14" to="/offers">
								{{ $t("nav.offers") }}
							</router-link>
						</li>
						<li class="nav-item pa-m-0 become-partner-li px-sm">
							<router-link @click="toggleNav" rel="noreferrer" class="d-block text-sm pa-m-10 f-m-14" to="/partner">
								{{ $t("nav.join_partener") }}
							</router-link>
						</li>
						<li class="nav-item">
							<!-- lang  -->
							<button class="lang flex_center p-0" @click="switchLang">
								<span v-if="$i18n.locale == 'en'">AR</span>
								<span v-else-if="$i18n.locale == 'ar'">EN</span>
								<i class="fa-solid fa-globe mx-2"></i>
							</button>
						</li>

						<li class="nav-item" v-if="isAuthed">
							<router-link @click="toggleNav" to="/cart" class="cart position-relative d-block">
								<span class="cart_numer"> {{ cart ? cart : 0 }}</span>
								<i class="fa-solid fa-cart-shopping"></i>
							</router-link>
						</li>
						<li class="nav-item">
							<router-link
								@click="goTONotification"
								v-if="isAuthed"
								to="/notification"
								class="cart position-relative d-block"
							>
								<span class="notification_count"> {{ notifications_count }}</span>
								<i class="fa-solid fa-bell"></i>
							</router-link>
						</li>

						<div class="dropdown profile br-5 my-2">
							<button
								class="btn dropdown-toggle br-5 pt-2 pb-2 main_btn"
								type="button"
								id="dropdownMenuButton1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span class="name"> {{ country_name }}</span>
							</button>
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
								<li class="mb-3" v-for="country in countries" :key="country.id">
									<button
										class="dropdown-item d-flex justify-content-start align-items-center"
										@click="chageCountry(country)"
									>
										<span class="mx-2 fw-6"> {{ country.name }} </span>
									</button>
								</li>
							</ul>
						</div>

						<div class="dropdown profile br-5 my-2" v-if="isAuthed">
							<button
								class="btn dropdown-toggle px-4 br-5 pt-2 pb-2 main_btn"
								type="button"
								id="dropdownMenuButton1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img :src="image" class="mx-2 imoji" width="30" height="30" alt="" />
								<span>{{ $t("nav.welcome") }} {{ username }}</span>

								<!-- <i class="fa-regular fa-user user_profile"></i> -->
							</button>
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
								<li class="mb-3">
									<router-link
										@click="toggleNav"
										class="dropdown-item d-flex justify-content-start align-items-center"
										to="/profile"
									>
										<span class="profile_icon flex_center">
											<i class="fa-solid fa-user-pen"></i>
										</span>
										<span class="mx-2 fw-6"> {{ $t("nav.profile") }} </span>
									</router-link>
								</li>

								<li class="mb-3">
									<router-link
										@click="toggleNav"
										class="dropdown-item d-flex justify-content-start align-items-center"
										to="/orders"
									>
										<span class="profile_icon flex_center">
											<i class="fa-solid fa-bag-shopping"></i>
										</span>
										<span class="mx-2 fw-6"> {{ $t("nav.orders") }} </span>
									</router-link>
								</li>
								<li class="mb-3">
									<router-link
										@click="toggleNav"
										class="dropdown-item d-flex justify-content-start align-items-center"
										to="/reservations"
									>
										<span class="profile_icon flex_center">
											<i class="fa-solid fa-bag-shopping"></i>
										</span>
										<span class="mx-2 fw-6"> {{ $t("nav.rerserve") }} </span>
									</router-link>
								</li>
								<li class="mb-3">
									<router-link
										@click="toggleNav"
										class="dropdown-item d-flex justify-content-start align-items-center"
										to="/points"
									>
										<span class="profile_icon flex_center">
											<i class="fa-solid fa-money-bill"></i>
										</span>
										<span class="mx-2 fw-6"> {{ $t("nav.points") }} </span>
									</router-link>
								</li>

								<li class="mb-3">
									<button
										class="dropdown-item d-flex justify-content-start align-items-center"
										@click.prevent="signOut"
									>
										<span class="profile_icon logout flex_center">
											<i class="fa-solid fa-right-from-bracket"></i>
										</span>
										<span class="mx-2 fw-6"> {{ $t("nav.logout") }} </span>
									</button>
								</li>
								<li class="mb-3">
									<button
										class="dropdown-item d-flex justify-content-start align-items-center"
										@click.prevent="deleteAccount"
									>
										<span class="profile_icon logout flex_center text-danger">
											<i class="fa-solid fa-right-from-bracket"></i>
										</span>
										<span class="mx-2 fw-6 text-danger">
											{{ $t("nav.delete") }}
										</span>
									</button>
								</li>
							</ul>
						</div>
						<li class="pa-m-0 d-flex justify-content-center my-2" v-if="!isAuthed">
							<router-link
								@click="toggleNav"
								to="/login"
								type="button"
								class="btn btn-login d-flex align-items-center f-m-14 pa-m-10"
								data-testid="login"
							>
								<span>
									{{ $t("nav.login") }}
								</span>
							</router-link>
						</li>
					</ul>

					<button class="btn toggleNabBtn dropdown profile" style="color: #fff" @click="toggleNav">
						<i class="fa-solid fa-bars-staggered"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
	<Toast />
</template>

<script>
import api from "@/core/api";
import Toast from "primevue/toast";
import {mapState} from "vuex";
export default {
	name: "MultivendorHeaderComponent",

	data() {
		return {
			isAuthed: false,
			username: "",
			image: "",
			country: "",
			countries: [],
			store: this.$store,
			country_id: "",
			country_name: this.$t("suad"),
			cart_length: 0,
		};
	},
	methods: {
		chageCountry(country) {
			this.country_id = country.id;
			this.country_name = country.name;
			localStorage.setItem("country_name", country.name);
			localStorage.setItem("country_id", country.id);
			this.$store.dispatch("changeCountry", country);
		},
		switchLang() {
			let lang = "ar";
			if (this.$i18n.locale == "ar") {
				lang = "en";
				this.arabic = false;
			}

			if (localStorage.getItem("locale")) {
				localStorage.removeItem("locale");
			}

			localStorage.setItem("locale", lang);
			this.arabic = true;

			location.reload();
		},
		goTONotification() {
			document.querySelector(".navbar-nav").classList.remove("active");
			this.$store.dispatch("setNotificationsCount", 0);
			localStorage.setItem("notification_count", 0);
		},
		toggleNav() {
			document.querySelector(".navbar-nav").classList.toggle("active");
		},
		// sign out
		async signOut() {
			const token = localStorage.getItem("token");
			// const token = JSON.parse(localStorage.getItem('user'))[0].token;
			const headers = {
				Authorization: `Bearer ${token}`,
				lang: "ar",
			};
			const fd = new FormData();
			await api.post(`user/logout?device_id=test00&device_type=web`, fd, {headers}).then((res) => {
				if (res.data.key == "success") {
					this.$toast.add({
						severity: "success",
						summary: res.data.msg,
						life: 3000,
					});
					window.location.href = "/login";
					localStorage.removeItem("token");
					localStorage.removeItem("user");
				} else {
					this.$toast.add({
						severity: "error",
						summary: res.data.msg,
						life: 3000,
					});
				}
			});
		},
		// sign out
		async deleteAccount() {
			const token = localStorage.getItem("token");
			// const token = JSON.parse(localStorage.getItem('user'))[0].token;
			const headers = {
				Authorization: `Bearer ${token}`,
				lang: "ar",
			};
			const fd = new FormData();
			fd.append("device_id", "test");
			fd.append("device_type", "web");
			await api.post(`user/delete-account`, fd, {headers}).then((res) => {
				if (res.data.key == "success") {
					this.$toast.add({
						severity: "success",
						summary: res.data.msg,
						life: 3000,
					});
					setTimeout(() => {
						this.$router.push("/login");
					}, 1000);
					localStorage.removeItem("token");
					localStorage.removeItem("user");
				} else {
					this.$toast.add({
						severity: "error",
						summary: res.data.msg,
						life: 3000,
					});
				}
			});
		},
		// get cart length
		async getCart() {
			try {
				const res = await api.get("user/get-cart", {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				});
				if (res.data.key === "success") {
					console.log(res.data.data.carts.length);
					this.$store.dispatch("setCartLength", res.data.data.carts.length);
				}
			} catch (error) {
				console.error("Error fetching cart:", error);
			}
		},
		async getNotification() {
			try {
				const token = localStorage.getItem("token");
				const headers = {
					Authorization: `Bearer ${token}`,
				};
				await api.get("user/notifications", {headers}).then((res) => {
					if (res.data.key === "success") {
						this.$store.dispatch("setNotificationsCount", localStorage.getItem("notification_count") || 0);
					}
				});
			} catch (error) {
				console.error("Error fetching cart:", error);
			}
		},
		async getCountries() {
			if (this.$store.countries?.length > 0) this.countries = this.$store.countries;
			else {
				try {
					await api.get("countries").then((res) => {
						this.countries = res.data.data;
						this.$store.dispatch("setCountries", this.countries);
					});
				} catch (e) {
					console.log(e);
				}
			}
		},
	},
	components: {
		Toast,
	},
	watch: {
		cart() {
			this.getCart();
		},
	},
	computed: {
		...mapState({
			cart: (state) => state.cart,
			notifications_count: (state) => state.notifications_count,
		}),

		currentCountry() {
			return this.$store.state.country;
		},
	},
	mounted() {
		this.getCountries();
		this.getCart();
		this.getNotification().then(() => {
			console.log(this.$store.state.notification_count);
		});
		if (localStorage.getItem("token")) {
			this.isAuthed = true;
		}
		if (localStorage.getItem("user")) {
			this.username = JSON.parse(localStorage.getItem("user")).first_name;
			this.image = JSON.parse(localStorage.getItem("user")).image;
		}
	},
};
</script>

<style lang="scss" scoped>
a {
	font-size: 13px;
}
li {
	display: flex;
	align-items: center;
}
.cart_numer {
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: #f10f0f;
	color: #fff;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	left: -5px;
	top: -5px;
}
.notification_count {
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: #f10f0f;
	color: #fff;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	left: -5px;
	top: -5px;
}
.lang {
	background-color: transparent;
	color: #fff;
	border: none;
	font-weight: bold;
}
.header {
	background: #734b21;
	padding-top: 5px;
	padding-bottom: 5px;
}
.logo {
	width: 150px;
	height: 90px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
.nav-item {
	a {
		color: #fff;
		text-decoration: none !important;
		font-size: 16px;
		font-weight: 600;
	}
	svg {
		font-size: 25px !important;
	}
}
.btn-login {
	color: #fff;
	border: 1px solid #fff;
	height: 45px;
}

.dropdown-menu {
	border: none !important;
	box-shadow: 0px 0px 10px #33333346;
}
.dropdown {
	&.profile {
		button.dropdown-toggle {
			min-width: 100px;
			background-color: #734b21 !important;
			color: #fff;
			border: 1px solid #fff;
			height: 45px;
		}
	}
}
</style>
