import {createRouter, createWebHistory} from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import HomeView from "../views/client/HomeView.vue";
import markets from "../views/client/marketsView.vue";
import market from "../views/client/singleMarket.vue";
import menu from "../views/client/menueView.vue";
import reserve from "../views/client/reserveView.vue";
import cart from "../views/client/cartView.vue";
import complete from "../views/client/completeOrder.vue";
import login from "../views/client/loginView.vue";
import register from "../views/client/registerView.vue";
import completeReserve from "../views/client/completeReserve.vue";
import offers from "../views/client/offersView.vue";
import forgetPassword from "../views/client/forgetPassword.vue";
import resetPassword from "../views/client/resetPassword.vue";
import profile from "../views/client/profileView.vue";
import privacy from "../views/client/privacyView.vue";
import terms from "../views/client/termsConditionView.vue";
import us from "../views/client/whoUsView.vue";
import contact from "../views/client/contactUS.vue";
import changePassword from "../views/client/changePassword.vue";
import orders from "../views/client/ordersView.vue";
import points from "../views/client/pointsView.vue";
import reservations from "../views/client/reservationsView.vue";
import reservationDetails from "../views/client/rerservationDetails.vue";
import orderDetails from "../views/client/orderDetails.vue";
import notification from "../views/client/notificationView.vue";
import partner from "../views/vendor/joinPartener.vue";

const routes = [
	{
		path: "/",
		component: DefaultLayout, // Wrap default layout
		children: [
			{
				path: "",
				name: "home",
				component: HomeView,
			},

			{
				path: "markets",
				name: "markets",
				component: markets,
			},
			{
				path: "market/:id",
				name: "market",
				component: market,
			},
			{
				path: "menu/:id",
				name: "menu",
				component: menu,
			},
			{
				path: "reserve",
				name: "reserve",
				component: reserve,
			},
			{
				path: "cart",
				name: "cart",
				component: cart,
			},
			{
				path: "complete",
				name: "complete",
				component: complete,
			},
			{
				path: "offers",
				name: "offers",
				component: offers,
			},
			{
				path: "profile",
				name: "profile",
				component: profile,
			},
			{
				path: "terms",
				name: "terms",
				component: terms,
			},
			{
				path: "privacy",
				name: "privacy",
				component: privacy,
			},
			{
				path: "contact",
				name: "contact",
				component: contact,
			},
			{
				path: "us",
				name: "us",
				component: us,
			},
			{
				path: "orders",
				name: "orders",
				component: orders,
			},
			{
				path: "points",
				name: "points",
				component: points,
			},
			{
				path: "reservations",
				name: "reservations",
				component: reservations,
			},
			{
				path: "orderDetails/:id",
				name: "orderDetails",
				component: orderDetails,
			},
			{
				path: "reservationDetails/:id",
				name: "reservationDetails",
				component: reservationDetails,
			},
			{
				path: "notification",
				name: "notification",
				component: notification,
			},
			{
				path: "/completeReserve/:id",
				name: "completeReserve",
				component: completeReserve,
				meta: {requiresAuth: true},
			},
			{
				path: "/partner",
				name: "partner",
				component: partner, // Separate component without layout
			},
		],
	},
	{
		path: "/login",
		name: "login",
		component: login, // No layout for login
	},
	{
		path: "/register",
		name: "register",
		component: register, // No layout for register
	},
	{
		path: "/forgetPassword",
		name: "forgetPassword",
		component: forgetPassword, // No layout for forget password
	},
	{
		path: "/resetPassword",
		name: "resetPassword",
		component: resetPassword, // No layout for reset password
	},

	{
		path: "/changePassword",
		name: "changePassword",
		component: changePassword,
	},
	{
		path: "/:pathMatch(.*)*",
		name: "notFound",
		component: () => import("../views/NotFound.vue"), // Lazy load the 404 page
	},
	{path: "/:pathMatch(.*)*", redirect: "/"}, // Catch-all route
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});
router.beforeEach((to, from, next) => {
	try {
		if (to.matched.some((record) => record.meta.requiresAuth)) {
			const token = localStorage.getItem("token");
			if (!token) {
				next({name: "login"});
			} else {
				next();
			}
		} else {
			next();
		}
	} catch (error) {
		console.error("Router Error:", error);
		next({name: "notFound"}); // Redirect to a fallback if an error occurs
	}
});

export default router;
