import {createStore} from "vuex";

export default createStore({
	state: {
		cart: 0, // initial value of cart
		notifications: [],
		notifications_count: 0,
		countries: [],
		country: {},
	},

	getters: {},

	mutations: {
		increament(state) {
			state.cart += 1;
			localStorage.setItem("cart_num", state.cart);
		},
		decreament(state) {
			if (state.cart > 0) {
				state.cart -= 1;
				localStorage.setItem("cart_num", state.cart);
			}
		},
		setCountry(state, payload) {
			state.country.id = payload.id;
			state.country.name = payload.name;
		},
		setCountries(state, payload) {
			state.countries = payload;
		},
		setNotifications(state, payload) {
			state.notifications = payload;
		},
		setCartLength(state, payload) {
			state.cart = payload;
		},
		setNotificationsCount(state, payload) {
			state.notifications_count = payload;
		},
	},

	actions: {
		changeCountry({commit}, country) {
			commit("setCountry", country);
		},
		setCountries({commit,state}, countries) {
			commit("setCountries", countries);
			if(state.country)commit("setCountry", countries[0]);

		},
		setNotifications({commit}, notifications) {
			commit("setNotifications", notifications);
		},
		setNotificationsCount({commit}, notifications_count) {
			commit("setNotificationsCount", notifications_count);
		},
		setCartLength({commit}, cart) {
			commit("setCartLength", cart);
		},
	},

	modules: {},
});
