import { useCookies } from '@vueuse/integrations/useCookies';

export function useAuth() {
    const { get, set, remove } = useCookies(['token']);
    const accessToken = get('token');

    return {
        get,
        accessToken,
        set,
        remove
    };
}
